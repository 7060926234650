.main-header__side-nav {
  background-color:$ui-05;
}

.main-header__user {
  margin-top: $spacing-03;
  margin-left: $spacing-04;
}
.main-header__email,
.main-header__uid {
  @include carbon--type-style('label-01');
  color: $icon-03;
}

.bx--side-nav__link-text{
  color: $field-01 !important;
}

.bx--side-nav__icon > svg{
  fill: $field-01;
}

.bx--side-nav__link:hover {
  background-color:$inverse-02 !important;
}

.bx--side-nav__link:active {
  background-color:$inverse-02 !important;
}

.bx--side-nav__link--current {
  background-color:$inverse-02 !important;
}
