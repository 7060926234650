svg.services__running {
	fill: $support-02;
}

svg.services__failed {
	fill: $support-01;
}

svg.services__warning {
	fill: $support-03;
}

.services__table {
	margin-top: $spacing-05;
}

.services__table_min {
	margin-top: 0;
}

.services-table__tab {
	padding-left: 2rem;
	min-height: 12 * $spacing-07;
	width: 99%;
}

#service-statistics__panel {
	padding-left: 0;
}

#service-logs__panel {
	padding-left: 0;
}
