@mixin login-page-background() {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: -$spacing-05;
    top: 0;
    right: -$spacing-05;
    bottom: 0;
    background: $ui-01;
    z-index: -1;
  }
}

@mixin main-page-background() {
  position: relative;
  min-height: 100vh;

  &::before {
    content: "";
    position: absolute;
    left: -$spacing-05;
    top: 0;
    right: -$spacing-05;
    bottom: 0;
    background: $ui-01;
    z-index: -1;
  }
}

// @mixin light-table-theme() {
//   background-color: $ui-02;
// }