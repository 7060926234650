.pools-page__table {
	margin-top: $spacing-07;
}

.pool-detail__c3 {
	padding-left: 0;
}

.pool-detail__c3_tile {
	min-height: 20 * $spacing-05;
}

.pool-detail__r1,
.pool-detail__r2,
.pool-detail__r3 {
	margin-top: $spacing-05;
}

.pool-detail__tile_payments,
.pool-detail__tile_cycles {
	margin-bottom: $spacing-05;
	min-height: 40 * $spacing-05;
	position: relative;
}

.pool-detail__tile_notes {
	margin-bottom: $spacing-05;
	min-height: 40 * $spacing-05;
	position: relative;
}

.pool-detail__tile_header {
	margin-bottom: $spacing-05;
	border-bottom: 0.2px solid $ui-03;
	@include carbon--type-style('expressive-heading-04');
}

.pool-detail__tile_profile {
	margin-bottom: $spacing-05;
	min-height: 25 * $spacing-05;
	position: relative;
}

.pool-detail__row_data {
	margin-bottom: $spacing-05;
}

.pool-detail__members {
	overflow-y: scroll;
	max-height: 12 * $spacing-05;
	// padding-left: $spacing-05;
	padding-right: $spacing-05;
}

.pool-detail__status {
	margin-right: $spacing-05;
}

.pool-detail__button_icon {
	position: absolute !important;
	top: 1.1 * $spacing-05 !important;
	right: $spacing-07 !important;
}

.pool-detail__tile_icon {
	position: absolute !important;
	top: $spacing-03 !important;
	right: $spacing-07 !important;
}

svg.pool-detail__icon-actions {
	fill: $interactive-02;
}

.pool-notify-appear {
	opacity: 0.01;
}

.pool-detail__notify {
	margin-bottom: $spacing-07;
}

.pool-detail__button {
	margin-right: $spacing-05;
	margin-bottom: $spacing-05;
	width: 3.5 * $spacing-07 !important;
}

.pool-detail__save_button {
	margin-right: $spacing-04;
}

.pool-payment__table,
.pool-cycle__table {
	margin-left: 0 * $spacing-05;
	margin-right: 0 * $spacing-05;
}

.pool-detail__status_r1 {
	margin-top: $spacing-02;
	margin-left: 0;
}

.pool-detail__no_items {
	margin-bottom: $spacing-05;
	@include carbon--type-style('body-short-01');
}
