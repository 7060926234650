.users-page__table {
	margin-top: $spacing-07;
}

svg.users-page__icon-green {
	fill: $support-02;
}

svg.users-page__icon-red {
	fill: $support-01;
}

svg.users-page__icon-black {
	fill: $inverse-02;
}

.user-detail__r1,
.user-detail__r2,
.user-detail__r3 {
	margin-top: $spacing-05;
}

.user-detail__c3 {
	padding-left: 0;
	// padding-right: 0;
}

.user-detail__tile_profile {
	margin-bottom: $spacing-05;
	min-height: 15 * $spacing-05;
	position: relative;
}

.user-detail__tile_phone_email {
	margin-bottom: $spacing-05;
	min-height: 9 * $spacing-05;
	position: relative;
}

.user-detail__tile_address {
	margin-bottom: $spacing-05;
	min-height: 19 * $spacing-05;
	position: relative;
}

.user-detail__tile_documents {
	margin-bottom: $spacing-05;
	min-height: 35 * $spacing-05;
}

.user-detail__view_media {
	max-width: 100%;
	max-height: 18 * $spacing-05;
	margin-bottom: $spacing-05;
}

.user-detail__tile_header {
	margin-bottom: $spacing-05;
	border-bottom: 0.2px solid $ui-03;
	@include carbon--type-style('expressive-heading-04');
}

.user-detail__button {
	margin-right: $spacing-05;
	margin-bottom: $spacing-05;
	width: 3.5 * $spacing-07 !important;
}

.user-detail__row_data {
	margin-bottom: $spacing-05;
}

.user-detail__button_icon {
	position: absolute !important;
	top: 1.1 * $spacing-05 !important;
	right: $spacing-07 !important;
}

.user-detail__tile_icon {
	position: absolute !important;
	top: $spacing-03 !important;
	right: $spacing-07 !important;
}

.user-detail__tile_button {
	margin-right: $spacing-07;
	margin-top: $spacing-04;
	margin-bottom: $spacing-04;
	width: 7 * $spacing-07 !important;
}

.user-detail__download_failed {
	margin-bottom: $spacing-05;
}

.user-detail__no_items {
	margin-bottom: $spacing-05;
	@include carbon--type-style('body-short-01');
}

.user-detail__notify {
	margin-bottom: $spacing-07;
}

.user-detail__label-header {
	margin-left: 0.9 * $spacing-06;
}

.user-detail__status {
	position: relative;
	display: inline-block !important;
	margin-top: $spacing-04;
	// margin-right: $spacing-01;
}

.user-detail__status_r1 {
	margin-top: $spacing-02;
}

svg.user-detail__icon-green {
	fill: $support-02;
}

svg.user-detail__icon-red {
	fill: $support-01;
}

td.user-table__icon-center {
	text-align: center;
}

.user-notify-appear {
	opacity: 0.01;
}

.user-detail__zoom {
	transition: transform 0.2s;
	margin-top: $spacing-07;
}

.user-detail__zoom:hover {
	transform: scale(2);
}

.user-detail__media_area {
	border: 1px solid black;
}

.user-detail__date_picker .bx--date-picker__input {
	width: 100% !important;
}

.user-detail__save_button {
	margin-right: $spacing-04;
}

.user-detail__button_set {
	margin-top: $spacing-05;
}

.user-detail__button_set-side {
	margin-top: $spacing-05;
}

svg.user-detail__icon-actions {
	fill: $interactive-02;
}

.user-detail__upload_header {
	margin-bottom: $spacing-05;
}

.user-detail__upload_text {
	margin-bottom: $spacing-07;
}

.user-detail__upload_area {
	max-height: 20 * $spacing-05;
	margin-bottom: $spacing-07;
}

.user-detail__tab_buttons {
	margin-left: 20 * $spacing-07 !important;
}

img.user__profile_photo {
	border-radius: 50%;
	width: 2 * $spacing-07;
	height: 2 * $spacing-07;
	margin-top: $spacing-02;
	transition: transform 0.2s;
}

.user__profile_photo-container {
	width: 2 * $spacing-07;
	height: 2 * $spacing-07;
}

img.user__profile_photo:hover {
	transform: scale(2);
}

.bx--file__container {
	margin-bottom: 2 * $spacing-05;
}

.bx--file__selected-file {
	background-color: $ui-01;
}

img.user__document_photo {
	width: 10 * $spacing-07;
	height: 5 * $spacing-07;
	margin-top: $spacing-02;
	margin-bottom: $spacing-07;
	transition: transform 0.2s;
}

img.user__document_photo:hover {
	transform: scale(3);
}

.user-detail__status_text {
	position: absolute;
	margin-left: $spacing-03;
}

.user-detail__tile_notes {
	margin-bottom: $spacing-05;
	min-height: 35 * $spacing-05;
	position: relative;
}

.user-detail__notes_box {
	overflow-y: scroll;
	height: 30 * $spacing-05;
	margin-bottom: $spacing-05;
}

.user-detail__tile_payments {
	margin-bottom: $spacing-05;
	min-height: 35 * $spacing-05;
	position: relative;
}

.user-payment__table {
	margin-left: 0 * $spacing-05;
	margin-right: 0 * $spacing-05;
}
