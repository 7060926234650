.data-table-cell--date-time {
  @include carbon--type-style("label-01");
}

.data-table-cell--id {
  @include carbon--type-style("code-02");
}

.susu__type_tag {
  width: 2.5 * $spacing-07;
}

.susu__status_tag {
  width: 2.5 * $spacing-07;
}

.payment__type_tag {
  width: 2.5 * $spacing-07;
}

.payment__status_tag {
  width: 2.5 * $spacing-07;
}

.group__name_tag {
  width: 3 * $spacing-07;
}

.image__name_tag {
  width: 6 * $spacing-07;
}

.main__page_load-content {
  background-color: $support-03;
}

.main__page_loader {
  margin-top: 40%;
}
