.home-page__text {
	margin-top: $spacing-03;
	@include carbon--type-style('body-short-01');
}

.home-page__r1 {
	margin-top: $spacing-07;
	margin-bottom: $spacing-07;
}

.home-page__r2 {
	margin-top: 0 * $spacing-01;
}

.home-page__tile {
	height: 7 * $spacing-07;
	position: relative;
}

.home-page__tile-r2 {
	height: 3 * $layout-07;
}

.home-page__tile-half {
	height: 3 * $spacing-07;
	position: relative;
}

.home-page__r-half {
	margin-bottom: $spacing-07;
}

.home-page__r-half-2 {
	margin-bottom: $spacing-07;
}

.home-page__tile-header {
	@include carbon--type-style('expressive-heading-04');
	margin-bottom: $spacing-03;
}

.home-page__tile-header-2 {
	@include carbon--type-style('expressive-heading-04');
	border-bottom: 0.2px solid $ui-03;
	margin-bottom: $spacing-05;
}

.home-page__tile-body {
	@include carbon--type-style('body-short-01');
}

.home-page__tile-icon {
	position: absolute;
	bottom: $spacing-05;
	right: $spacing-05;
}

.home-page__tile-icon-half {
	position: absolute;
	bottom: $spacing-05;
	right: $spacing-05;
}

.home-page__tutorial-body,
.home-page__announce-body {
	padding-top: $spacing-05;
}
