.dashboard-page__r1 {
	margin-top: $spacing-07;
}

.dashboard-page__tile_1 {
	height: 21 * $spacing-07;
}

.dashboard-page__tile_2 {
	height: 4.5 * $spacing-07;
	position: relative;
}

.dashboard-page__tile_icon {
	position: absolute;
	top: $spacing-05;
	right: $spacing-05;
}

.dashboard-page__r2 {
	margin-top: $spacing-07;
}

.dashboard-page__rs {
	margin-bottom: $spacing-07;
}

.dashboard-page__chart {
	height: 10 * $spacing-07;
}

.dashboard-page__h2 {
	text-align: left;
	@include carbon--type-style('productive-heading-02');
}

.dashboard-page__h2-sub {
	text-align: left;
	margin-top: 0.5 * $spacing-07;
	color: $ui-04;
	@include carbon--type-style('productive-heading-01');
}

.dashboard-page__h3 {
	margin-top: 0.25 * $spacing-07;
	text-align: left;
	@include carbon--type-style('productive-heading-05');
}

.dashboard-page__h3_unit {
	margin-bottom: 0.1 * $spacing-07;
	padding-left: 0.25 * $spacing-07;
	text-align: bottom;
	display: flex;
	align-items: flex-end;
	@include carbon--type-style('productive-heading-03');
}

.dashboard-page__h3_span {
	display: flex;
	flex-direction: row;
}

.dashboard-page__table {
	margin-top: $spacing-07;
}

.dashboard-page__table_list {
	padding: 1.05 * $spacing-04;
	@include carbon--type-style('body-long-01');
}

.dashboard-page__table_status {
	text-align: center;
}

svg.dashboard-page__error {
	fill: $support-01;
}

svg.dashboard-page__running {
	fill: $support-02;
}

svg.dashboard-page__down {
	fill: $support-03;
}

.services-table__code {
	@include carbon--type-style('code-01');
}

.services__table_min-content {
	overflow-y: scroll;
	height: 18 * $spacing-07;
}
