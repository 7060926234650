.content-container__content {
  @include main-page-background;
  padding-top: $spacing-07;
  padding-left: 4*$layout-05;
}

// .content-container__content {
//   @include main-page-background;
//   padding-top: $spacing-07;
//   padding-left: $layout-05;
// }