.login-page {
	@include main-page-background;
	padding-top: $spacing-07;
}

.login-page__content {
	width: 12 * $spacing-09;
	margin: auto;
}

.login-page__form-tile {
	padding-top: $spacing-05;
	padding-bottom: 0;
	padding-left: 0;
	padding-right: 0;
	margin-top: $spacing-09;
}

.login-page__form-header {
	margin-left: $spacing-05;
	margin-right: $spacing-05;
	margin-bottom: $spacing-07;
}

.login-page__form-field {
	margin-left: $spacing-05;
	margin-right: $spacing-05;
	margin-bottom: $spacing-07;
}

.login-page__notification {
	margin-bottom: $spacing-07;
}

.login-page__button-container {
	position: relative;
}

.login-page__button {
	width: 50%;
	margin-left: 50%;
}
